const getUid = (employer) => employer?.uid;

const getEmployerName = (employer) => employer?.name;

const getEmployerLogo = (employer) => employer?.companylogo;

const getEmployerLogoMasked = (employer) => employer?.companylogoMasked;

const getEmployerMetaImage = (employer) => employer?.imageMeta?.uid;

export default {
  getUid,
  getEmployerName,
  getEmployerLogo,
  getEmployerLogoMasked,
  getEmployerMetaImage,
};
