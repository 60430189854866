import { fetchData } from "../../../libs/Fetch";

// const additionalParams = 'type=1578058625';
const apiBasePath = "/rest/v1/employer";

const fetch = (url, payload = {}, method = "GET") => {
  url = apiBasePath + url;
  return fetchData(url, payload, method);
};

/**
 * Fetch search request
 * @param limit
 * @param start
 * @param employers
 * @param onlyWithProfile
 * @param additionalParams
 */
const fetchEmployerList = (
  limit = 20,
  start = 0,
  employers = [],
  onlyWithProfile = true,
  additionalParams = {}
) => {
  let url = "/list/";

  let request = {
    params: {
      type: "internal",
      ...additionalParams,
    },
    sortings: [{ field: "name", direction: "ASC" }],
    page: {
      limit: limit,
      start: start,
    },
  };

  if (onlyWithProfile) {
    request.params.profilePublished = true;
  }

  if (employers.length > 0) {
    request.params.uids = employers;
  }
  return fetch(url, request, "POST");
};

/**
 * Fetch search request
 * @param slug
 */
const fetchEmployerBySlugOrId = (slug) => {
  let url = `/show/${slug}`;
  return fetch(url, {}, "POST");
};

export default {
  fetchEmployerList,
  fetchEmployerBySlugOrId,
};
