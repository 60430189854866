import React from "react";
import PagePathUtility from "../../../../libs/PagePathUtility";
import ShortTeaser from "../../../../components/ShortTeaser";
import ExtendedTeaser from "../../../../components/ExtendedTeaser";
import ContentApiService from "../../../cms_content/services/ContentApiService";
import GeneralUtility from "../../../../libs/GeneralUtility";

const employerTeaser = ({
  employer,
  imageOverride = "",
  titleOverride = "",
  textOverride = "",
  linkOverride = "",
  buttonTextOverride = "",
  layout = "default",
  bgImagePreload = false,
  onLinkClick,
  textBackgroundColor = "transparent",
}) => {
  const employerDetailLink = PagePathUtility.getPathConfig("employerDetail", {
    ...employer,
  });

  const employerTeaserBgImageUid = employer?.imageTeaser?.uid ?? "";
  const specialEmployerTeaserBgImageUid =
    imageOverride || employer?.imageTeaser2?.uid || employerTeaserBgImageUid;
  const bgColor = employer?.ciColorMain || "#737373";
  const topImage = employer?.companylogoMasked?.uid
    ? ContentApiService.getImageUrl(employer?.companylogoMasked?.uid, {
        height: 100,
      })
    : "";

  const topImageDimensionCategory = GeneralUtility.categorizeImageRatio(
    employer?.companylogoMasked?.originalWidth,
    employer?.companylogoMasked?.originalHeight
  );

  let content = "";
  switch (layout) {
    case "extended":
      content = (
        <ExtendedTeaser
          title={titleOverride || employer.teaserTitle}
          data={{ name: employer.nameShort || employer.name }}
          text={textOverride || employer.teaserText}
          textButton={buttonTextOverride || "Arbeitgeberprofil"}
          sizeLarge={4}
          sizeMedium={6}
          sizeSmall={12}
          linkHref={linkOverride || employerDetailLink}
          bgImageSrc={ContentApiService.getImageUrl(
            specialEmployerTeaserBgImageUid,
            { width: 1024, cropVariant: "vertical_rect" }
          )}
          topImageSrc={topImage}
          topImageTextReplacement={
            topImage ? "" : employer.nameShort || employer.name
          }
          topImageAdditionalClass={topImageDimensionCategory}
          showImageOnHover={false}
          toCenter={false}
        />
      );
      break;
    case "extendedTile":
      content = (
        <ExtendedTeaser
          title={titleOverride || employer?.teaserTitle}
          data={{ name: employer?.nameShort || employer?.name }}
          text={textOverride || employer?.teaserText}
          textButton={buttonTextOverride || "Arbeitgeberprofil"}
          sizeLarge={4}
          sizeMedium={6}
          sizeSmall={12}
          linkHref={linkOverride || employerDetailLink}
          bgImageSrc={ContentApiService.getImageUrl(
            specialEmployerTeaserBgImageUid,
            { width: 1024, cropVariant: "tile" }
          )}
          bgImageSrcPreload={bgImagePreload}
          topImageSrc={topImage}
          topImageTextReplacement={
            topImage ? "" : employer?.nameShort || employer?.name
          }
          topImageAdditionalClass={topImageDimensionCategory}
          showImageOnHover={false}
          toCenter={false}
          onLinkClick={onLinkClick}
          textBackgroundColor={textBackgroundColor}
        />
      );
      break;
    case "employerTile":
      content = (
        <ShortTeaser
          title={titleOverride || employer.nameShort || employer.name}
          data={{ name: employer.nameShort || employer.name }}
          sizeLarge={4}
          sizeMedium={6}
          sizeSmall={12}
          linkHref={employerDetailLink}
          bgHoverColor={bgColor}
          bgImageSrc={ContentApiService.getImageUrl(employerTeaserBgImageUid, {
            width: 1024,
            cropVariant: "tile",
          })}
          bgImageSrcPreload={bgImagePreload}
          topImageSrc={""}
          showImageOnHover={false}
          badgeLabel={
            employer.recruitingPackageCustomer ? "Top-Arbeitgeber" : ""
          }
        />
      );
      break;
    default:
      content = "";
      break;
  }

  return content;
};

export default employerTeaser;
