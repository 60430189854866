import React from "react";
import { Swiper } from "swiper/react";
import SwiperCore, { Autoplay, Lazy, Navigation, Pagination } from "swiper";
import SliderPagination from "../SliderPagination/SliderPagination";
import styles from "./SwiperSlider.module.scss";

SwiperCore.use([Autoplay, Navigation, Lazy, Pagination]);

const SwiperSlider = React.forwardRef((props, ref) => {
  let defaultProps = {
    scrollbar: { draggable: true },
    watchOverflow: true,
  };

  let {
    wrapperClassName = "",
    navigation = false,
    pagination = false,
    ...sliderProps
  } = props;

  if (navigation && Object.values(navigation).length > 0) {
    // add custom navigation
    sliderProps.navigation = navigation;
  } else if (!navigation) {
    // add default navigation
    defaultProps.navigation = {
      prevEl: `#swiper-button-prev-${props.id}`,
      nextEl: `#swiper-button-next-${props.id}`,
    };
  }

  if (pagination && Object.values(pagination).length > 0) {
    // add custom navigation
    sliderProps.pagination = navigation;
  } else if (!pagination) {
    // add default navigation
    defaultProps.pagination = {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: (index, className) =>
        '<span class="' + className + '"></span>',
    };
  }

  sliderProps = { ...defaultProps, ...sliderProps };

  return (
    <div className={`${styles.sliderWrapper} ${wrapperClassName || ""}`}>
      <Swiper {...sliderProps} ref={ref}>
        {props.children}
        <SliderPagination />
      </Swiper>

      {sliderProps.navigation &&
        Object.values(sliderProps.navigation).length > 0 && (
          <>
            <div
              id={`swiper-button-prev-${props.id}`}
              className="swiper-button-prev"
            />
            <div
              id={`swiper-button-next-${props.id}`}
              className="swiper-button-next"
            />
          </>
        )}
    </div>
  );
});

export default SwiperSlider;
