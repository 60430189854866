import React, { useEffect, useRef, useState } from "react";
import GeneralUtility from "../../../../../libs/GeneralUtility";
import EmployerTeaser from "../../employer/EmployerTeaser";
import SwiperSlider from "../../../../cms_content/components/global/SwiperSlider/SwiperSlider";
import { SwiperSlide } from "swiper/react";
import EmployerLib from "../../../libs/EmployerLib";

const EmployerTeaserWidget = ({
  id,
  items = [],
  random,
  layout,
  trackingEnable = 0,
  itemCount = 0,
}) => {
  const [trackingActivated, setTrackingActivated] = useState(false);
  const [employerItems, setEmployerItems] = useState(items);
  const sliderRef = useRef(null);

  /**
   * send event to analytics
   * @param ceId
   * @param employerName
   * @param employerId
   */
  const sendEmployerTracking = (ceId, employerName, employerId) => {
    if (!trackedEmployers.includes(employerId) && employerId) {
      window.dataLayer.push({
        event: "employerTeaserView",
        item: `EmployerTeaser-${ceId}`,
        employerId: employerId,
        employerName: employerName,
      });
      trackedEmployers.push(employerId);
    }
  };

  useEffect(() => {
    let updatedItems = items;
    if (random && items.length > 0) {
      updatedItems = GeneralUtility.shuffle(Object.values(items));
      setEmployerItems(updatedItems);
    }

    if (itemCount > 0 && updatedItems.length > itemCount) {
      updatedItems = updatedItems.slice(0, itemCount);
      setEmployerItems(updatedItems);
    }

    setTrackingActivated(!!trackedEmployers);
    if (trackingEnable && updatedItems.length > 0) {
      const employer = updatedItems[0].employer;
      sendEmployerTracking(
        id,
        EmployerLib.getEmployerName(employer),
        EmployerLib.getUid(employer)
      );
    }
  }, []);

  let trackedEmployers = [];

  /**
   * handle tracking of shown employers by slide change
   * @param slider
   */
  const slideChangeTracking = (slider) => {
    if (
      !trackingEnable ||
      !GeneralUtility.isElementInViewport(sliderRef.current)
    )
      return;
    const slide = slider.slides[slider.activeIndex];

    const employerId = slide.dataset.id || "";
    const employerName = slide.dataset.label || "";
    if (trackingActivated) {
      sendEmployerTracking(id, employerName, employerId);
    }
  };

  let renderContent = "";
  switch (parseInt(layout)) {
    // Erweiteter Arbeitgeberteaser
    case 100:
      renderContent = (
        <div className="grid-x grid-margin-x grid-margin-y">
          {employerItems &&
            employerItems.map(
              ({ employer, header, text, image, link, buttonText }, index) => (
                <EmployerTeaser
                  employer={employer}
                  imageOverride={image}
                  titleOverride={header}
                  textOverride={text}
                  linkOverride={link}
                  buttonTextOverride={buttonText}
                  key={index}
                  layout="extended"
                />
              )
            )}
        </div>
      );
      break;
    // Erweiteter Arbeitgeberteaser als Slider
    case 101:
      if (employerItems.length === 0) {
        // render empty employer teaser as placeholder
        renderContent = <EmployerTeaser layout="extendedTile" />;
      } else {
        renderContent = (
          <SwiperSlider
            id={id}
            slidesPerView={1}
            autoplay={{ delay: 5000 }}
            scrollbar={{ draggable: true }}
            effect="fade"
            loop={true}
            lazy={true}
            pagination={{}}
            navigation={{}}
            watchOverflow={true}
            onSlideChange={slideChangeTracking}
            ref={sliderRef}
          >
            {employerItems &&
              employerItems.map(
                (
                  {
                    employer,
                    image,
                    header,
                    text,
                    link,
                    buttonText,
                    textBackgroundColor,
                  },
                  index
                ) => (
                  <SwiperSlide
                    key={index}
                    data-id={EmployerLib.getUid(employer)}
                    data-label={EmployerLib.getEmployerName(employer)}
                  >
                    <EmployerTeaser
                      employer={employer}
                      imageOverride={image}
                      titleOverride={header}
                      textOverride={text}
                      linkOverride={link}
                      buttonTextOverride={buttonText}
                      key={EmployerLib.getUid(employer)}
                      layout="extendedTile"
                      bgImagePreload={index === 0}
                      onLinkClick={() =>
                        trackingEnable &&
                        window.dataLayer.push({
                          event: "employerTeaserClick",
                          item: `EmployerTeaser-${id}`,
                          employerId: EmployerLib.getUid(employer),
                          employerName: EmployerLib.getEmployerName(employer),
                        })
                      }
                      textBackgroundColor={textBackgroundColor}
                    />
                  </SwiperSlide>
                )
              )}
          </SwiperSlider>
        );
      }
      break;
    default:
      // Standard Arbeitgeberkachel
      renderContent = (
        <div className="grid-x grid-margin-x grid-margin-y">
          {employerItems &&
            employerItems.map(
              ({ employer, header, image, text, link, buttonText }, index) => (
                <EmployerTeaser
                  employer={employer}
                  titleOverride={header}
                  textOverride={text}
                  linkOverride={link}
                  imageOverride={image}
                  buttonTextOverride={buttonText}
                  key={index}
                  layout="employerTile"
                />
              )
            )}
        </div>
      );
  }

  return renderContent;
};

export default EmployerTeaserWidget;
