import React from "react";
import Link from "next/link";
import styles from "./ExtendedTeaser.module.scss";
import Image from "../Image/index";
import Icon from "../Icon/index";
import Button from "../Button";

const ExtendedTeaser = ({
  title,
  subtitle,
  text,
  textButton,
  bgImageSrc,
  bgImageSrcPreload = false,
  topImageSrc,
  topImageAdditionalClass,
  topImageTextReplacement,
  sizeLarge = "3",
  sizeMedium = "6",
  sizeSmall = "12",
  layout = "default",
  linkHref = "#!",
  linkAs = "",
  bgHoverColor = "",
  showImageOnHover = true,
  data = {},
  bottomTags = [],
  toCenter = false,
  onLinkClick,
  textBackgroundColor = "transparent",
}) => {
  let backgroundMode = "default";
  let style = {};
  let ExtendedTeaserStyle = {};

  if (bgHoverColor && !bgImageSrc) {
    backgroundMode = "colorOnHoverNoBgImage";
    style = {
      backgroundColor: bgHoverColor,
    };
  } else if (!bgHoverColor && bgImageSrc) {
    backgroundMode = "imageAlways";

    style = {
      backgroundColor: bgHoverColor,
    };
  } else if (showImageOnHover && bgHoverColor && bgImageSrc) {
    backgroundMode = "imageOnHover";

    style = {
      backgroundColor: bgHoverColor,
    };
  } else if (!showImageOnHover && bgImageSrc && bgHoverColor) {
    backgroundMode = "colorOnHover";

    style = {
      backgroundColor: bgHoverColor,
    };
  }

  Object.keys(data).forEach((key) => {
    data[`data-${key}`] = data[key];
    delete data[key];
  });

  return (
    <div
      className={`cell small-${sizeSmall}  medium-${sizeMedium} large-${sizeLarge} ${
        styles["layout-" + backgroundMode]
      } layout-${layout}`}
      {...data}
    >
      <div
        className={`teaser ${styles.ExtendedTeaser} ${
          toCenter && styles.center
        }`}
        style={ExtendedTeaserStyle}
      >
        {bgHoverColor && <div className={styles.bgColor} style={style} />}
        {bgImageSrc && (
          <Image
            className={`${styles.bgImage} extendedbgImage`}
            src={bgImageSrc}
            nextOptimizedImage={true}
            priority={bgImageSrcPreload}
            layout={"fill"}
          />
        )}
        {topImageSrc && (
          <Image
            className={`${styles.topImage} ${
              styles["topImage--" + topImageAdditionalClass]
            }`}
            src={topImageSrc}
            lazy={true}
          />
        )}
        {!topImageSrc && topImageTextReplacement && (
          <div className={styles.topImage}>{topImageTextReplacement}</div>
        )}

        <div
          className={`${styles["textBackgroundColor--" + textBackgroundColor]}`}
        >
          {title && <h3 className={styles.title}>{title}</h3>}
          {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}

          {text && <p className={styles.text}>{text}</p>}
          {textButton && linkHref && (
            <Link href={linkHref} passHref prefetch={false}>
              <Button
                layout="hollowForDark"
                text={textButton}
                onLinkClickCustom={onLinkClick}
              />
            </Link>
          )}
        </div>

        {bottomTags && (
          <ul className={`${styles.bottomTags} no-list`}>
            {bottomTags.map((tag, index) => (
              <li key={index}>
                <Icon name={tag.icon ?? ""} size="small" />
                <span>{tag.label}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ExtendedTeaser;
