import React from "react";
import CeWrapper from "../../../global/CeWrapper/index";
import EmployerTeaserWidget from "../../../../../jobs/components/widgets/EmployerTeaserWidget/EmployerTeaserWidget";
import CeDataGetter from "../../../../libs/CeDataGetter";
import useSWR from "swr";
import EmployerApiService from "../../../../../jobs/services/EmployerApiService";
import GeneralUtility from "../../../../../../libs/GeneralUtility";
import getConfig from "next/config";

/**
 * fetch all employer if no employer selected in backend
 * @param url
 * @param random
 * @param employers
 * @param itemCount
 * @returns {Promise<*>}
 */
async function fetcher(url, random, employers, itemCount) {
  const showEmployers = employers ? employers.split(",") : [];
  const res = await EmployerApiService.fetchEmployerList(
    9999,
    0,
    showEmployers
  );

  let employersResult = res.result.employers;

  if (random) {
    employersResult = GeneralUtility.shuffle(Object.values(employersResult));
  }

  if (itemCount) {
    employersResult = employersResult.slice(0, itemCount);
  }

  // convert to object array with employer property
  return employersResult.map((employer) => ({ employer }));
}

const ce = ({ ceData }) => {
  const { publicRuntimeConfig } = getConfig();
  let {
    employerSlides: items = [],
    random = 0,
    itemCount = 0,
    trackingEnable = 0,
  } = ceData.contentParts;

  if (!items || items.length === 0) {
    // fetch all employers from api
    const { data, error } = useSWR(
      ["/employers", random, items.join(","), itemCount],
      fetcher,
      {
        ...publicRuntimeConfig.globalSwrConfig,
        revalidateOnMount: true,
        initialData: [],
      }
    );
    if (error) return <div>failed to load</div>;
    if (!data) return null;
    items = data;
  }

  return (
    <CeWrapper ceData={ceData} renderHeader={true}>
      <EmployerTeaserWidget
        id={CeDataGetter.getUid(ceData)}
        random={parseInt(random)}
        items={items}
        itemCount={parseInt(itemCount)}
        trackingEnable={parseInt(trackingEnable)}
        layout={ceData.properties.layout}
      />
    </CeWrapper>
  );
};

export default ce;
